import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'btn-cell-renderer',
  template: `
    <div class="flex justify-between w-24 mt-0.5 items-center">
     <button
        color="primary"
        class="!bg-primary-500 w-16 h-8 leading-none  rounded text-white "
        (click)="clickedExpiredButton()"
      >
        Expire
      </button>
    </div>
  `,
})
export class ExpireButton {
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  clickedExpiredButton() {
    this.params.clicked({ clickedExpireBtn: true, clickedEditBtn: false, data: this.params.data });
  }

 
}
