import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import {
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { Environment } from '@env/environment.interface';
import { getEnvironmentConfig } from '@env/environment.loader';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { ChangeProgramModalComponent } from './change-program-modal/change-program-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedUiDialogModule } from '@sg-overrides/ui/dialog';
import { programService } from 'libs/ui/overrides/src/lib/overrides/program.service';
import { ErrorService } from 'libs/ui/shared/src/lib/ui-errors/error.service';
import { trpcError$ } from '@sg-overrides/shared/util-trpc';
import { ErrorMessage } from 'libs/ui/shared/src/lib/ui-errors/interfaces/error-message.interface';
import { UiSharedModule } from '@sg-overrides/ui/shared';

const envConfig: Environment = getEnvironmentConfig(window.origin);

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: envConfig.clientId,
      authority: `https://login.microsoftonline.com/${envConfig.tenantId}`,
      redirectUri: envConfig.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      allowRedirectInIframe: true,
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);
  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

const routes: Routes = [
  {
    path: '',
    redirectTo: 'overrides',
    pathMatch: 'full',
  },
  {
    path: 'overrides',
    loadChildren: () =>
      import('@sg-overrides/ui/overrides').then((m) => m.UiOverridesModule),
  },
  {
    path: 'chargeback',
    loadChildren: () =>
      import('@sg-overrides/ui/charge-back').then((m) => m.UiChargeBackModule),
  },
  {
    path: 'chargebackConfig',
    loadChildren: () =>
      import('@sg-overrides/ui/chargeback-config').then((m) => m.UiChargebackConfigModule),
  },
  {
    path: 'add-payee',
    loadChildren: () =>
      import('@sg-overrides/ui/add-payee').then((m) => m.UiAddPayeeModule),
  },
];
@NgModule({
  declarations: [AppComponent, LayoutComponent, ChangeProgramModalComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatToolbarModule,
    MsalModule,
    MatSidenavModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
  }),
    MatDialogModule,
    SharedUiDialogModule,
    UiSharedModule
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    MsalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   // useClass: ApiInterceptor,
    //   multi: true,
    // },
    programService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private errorService: ErrorService) {
    trpcError$.subscribe((errorResponse) => {
      this.errorService.showTrpcError(errorResponse);
    })
  }
}
