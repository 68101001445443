import { ICellEditorComp, ICellEditorParams } from '@ag-grid-community/core';

// pulled from https://www.ag-grid.com/javascript-data-grid/component-cell-editor/
export class NumericCellEditorAddPayee implements ICellEditorComp {
  private eInput!: HTMLInputElement;
  private charPressInit = false;

  init(params: ICellEditorParams) {
    console.log("Inside Input", params)
    // const existingOverride = params.data.products.find(
    //     (product: any) =>
    //       product.productType.toLocaleLowerCase() ===
    //         (params.colDef.type as string).toLocaleLowerCase() ?? ''
    //   );
    const fieldName: string = params.colDef.field ?? '';
    this.eInput = document.createElement('input');
    this.eInput.setAttribute('type', 'number');
    this.eInput.classList.add('editable-grid-cell');
    this.eInput.innerHTML = params.value ?? '';
    this.eInput.type = 'number';

    this.charPressInit = !!params.charPress;
    // if (params.charPress && this.isCharNumeric(params.charPress)) {
    //   this.eInput.value = params.charPress;
    // } else {
    //   if (params.value !== undefined && params.value !== null) {
    //     // For some reason we're occasionally getting weird floating point numbers from the backend.
    //     // For example, 24.2 will be returned from the backend as 24.200000000000003,
    //     // so we format the number and convert it back to a number to round it appropriately.
    //     const value = params.formatValue(params.value).split(',').join('');
    //     this.eInput.value = value;
    //   }
    // }

    this.eInput.value = params.value
    // ? existingOverride.amount
    // : null

    this.eInput.addEventListener('keypress', (event) => {
      if (this.isKeyPressedNavigation(event)) {
        event.stopPropagation();
      }
    });
  }

  getValue() {
    return this.eInput.value;
  }

  getGui() {
    return this.eInput;
  }

  afterGuiAttached() {
    if (this.charPressInit) {
      this.eInput.focus();
    } else {
      this.eInput.select();
    }
  }

  private isKeyPressedNavigation(event: KeyboardEvent) {
    return event.keyCode === 39 || event.keyCode === 37;
  }

  private isCharNumeric(charStr: string | null) {
    return charStr != null && !!/\d/.test(charStr);
  }
}
