import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OverrideStore } from '../overrides.store';
import {
  OverridesGridService,
  programAgentDetails,
} from './overrides-grid.service';
import { ChargebackStore } from '../../../../charge-back/src/lib/store/chargeback-store';
import { ChargebackConfigStore } from '../../../../chargeback-config/src/lib/store/chargeback-config.store';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class programService {
  selectedProgram$ = new BehaviorSubject<programAgentDetails | null>(null);
  selectedRoute$ = new BehaviorSubject<string | null>(null);
  chargebackStatus$ = new BehaviorSubject<string | null>(null);

  constructor() {
    this.getSelectedProgramDetails();
  }

  changeProgram(program: programAgentDetails) {
    localStorage.setItem('programDetails', JSON.stringify(program));
    this.selectedProgram$.next(program);

    // this.overrideStore.updateSelectedProgram(program)
    // this.chargebackStore.updateSelectedProgram(program)
    // this.chargebackConfigStore.updateSelectedProgram(program)
    // this.overrideService.getDealerByProgram(program.agentCode);
  }

  clearProgram() {
    localStorage.removeItem('programDetails');
    this.selectedProgram$.next(null);
    // this.overrideStore.updateSelectedProgram(null);
    // this.chargebackStore.updateSelectedProgram(null);
    // this.chargebackConfigStore.updateSelectedProgram(null);
  }

  getSelectedProgramDetails() {
    const programDetails = localStorage.getItem('programDetails');
    if (programDetails) {
      // this.selectedProgram$.next(JSON.parse(programDetails));
      //   this.overrideService.getDealerByProgram(JSON.parse(programDetails).agentCode);
    }
  }
}
