import { formatDate } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs';
import { GridProductColumnType } from '../overrides.store';
import {
  overrideData,
  overrideDataProducts,
} from '../overrides/overrides-grid.service';

@Component({
  selector: 'sg-overrides-expire-dialog',
  templateUrl: './expire-dialog.component.html',
  styleUrls: ['./expire-dialog.component.scss'],
})
export class ExpireDialogComponent {
  form: FormGroup = this.formBuilder.group({
    endDate: ['', Validators.required],
  });
  productListToExpire: overrideDataProducts = [];

  isFormInitialized: boolean = false;

  error: boolean = false;

  headerText: string = 'Expire Overrides';

  constructor(
    private dialogRef: MatDialogRef<ExpireDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      overrideDetails: overrideData;
      gridDes: GridProductColumnType[];
    },
    private formBuilder: FormBuilder
  ) {
   
    this.productListToExpire = this.data.overrideDetails.products.filter(
      (product) => product.allowExpire
    );
    this.generateForm();

    this.form.valueChanges
      .pipe(
        map((data) => {
          this.error = false;
          let detailRows = (this.data.overrideDetails as any)['detailViewRow'];
          if (detailRows) {
            let filteredDetailRows: any[] = [];
            Object.keys(data).forEach((key) => {
              if (key !== 'endDate' && data[key]) {
                let filtered = detailRows.map((detailRow: any) =>
                  detailRow.products.filter((product: any) => {
                    let productType = this.data.gridDes.find(
                      (des) => des.productTypeDescription === key
                    )?.productTypeCode;
                    return product.productType === productType;
                  })
                );
                filtered.forEach((filteredData: any) => {
                  filteredDetailRows.push(...filteredData);
                })
              }
            });
            if (data.endDate) {
              const endDate = formatDate(data.endDate, 'yyyy-MM-dd', 'en');

              filteredDetailRows.forEach((rowData) => {
                if (endDate < rowData.endDate) {
                  this.error = true;
                }
              });
            }
          }
        })
      )
      .subscribe();
  }

  generateForm() {
    this.productListToExpire = this.productListToExpire.map((product) => {
      const productDesc = this.data.gridDes.find(
        (des) =>
          des.productTypeCode.toLocaleLowerCase() ===
          product.productType.toLocaleLowerCase()
      );
      return {
        ...product,
        productType: productDesc?.productTypeDescription ?? product.productType,
      };
    });
    this.productListToExpire.forEach((product) => {
      this.form.addControl(product.productType, this.formBuilder.control(false));
    });
    this.isFormInitialized = true;
  }

  save() {
    if (this.form.valid) {
      let selectedProductDetails: overrideDataProducts = [];
      this.productListToExpire.forEach((product) => {
        if (this.form.controls[`${product.productType}`]?.value) {
          selectedProductDetails.push(product);
        }
      });
      selectedProductDetails = selectedProductDetails.map((product) => {
        const productDesc = this.data.gridDes.find(
          (des) =>
            des.productTypeDescription.toLocaleLowerCase() ===
            product.productType.toLocaleLowerCase()
        );
        return {
          ...product,
          productType: productDesc?.productTypeCode ?? product.productType,
        };
      });
      if (this.productListToExpire.length > 0)
        this.dialogRef.close({
          endDate: formatDate(
            this.form.controls['endDate'].value,
            'yyyy-MM-dd',
            'en'
          ),
          selectedProductDetails,
          overrideDetails: this.data.overrideDetails,
        });
    }
  }
}
