<div class="h-full flex flex-col item-stretch justify-start">
  <sg-overrides-dialog-header [headerText]="headerText"> </sg-overrides-dialog-header>

  <div class="flex mb-4">
    <div class="flex flex-grow">
      <b class="mr-1.5">Payee Name: </b>
      <p> {{ data.overrideDetails.payeeName }}</p>
    </div>
    <div class="flex flex-grow">
      <b class="mr-1.5">Payee Code: </b>
      <p> {{ data.overrideDetails.payeecode }}</p>
    </div>
  </div>

  <form [formGroup]="form">
    <table class="table table-compact w-full border-2 border-solid">
      <tr class="border-2 border-solid">
        <th class="w-1/2 border-2 border-solid text-left px-2">Product Type</th>
        <th class="w-1/4 border-2 border-solid text-left px-2">Amount</th>
        <th class="w-1/5 border-2 border-solid text-left px-2">Start Date</th>
        <th class="w-7 border-2 border-solid"></th>
      </tr>
      <tr
        *ngFor="let product of productListToExpire"
        class="border-2 border-solid"
      >
        <td class="border-2 border-solid px-2">{{ product.productType | uppercase }}</td>
        <td class="border-2 border-solid text-right px-2">
          ${{ product.amount.toFixed(2) }}
        </td>
        <td class="border-2 border-solid px-2">
          {{ product.startDate }}
        </td>
        <td class="border-2 border-solid text-center">
          <mat-checkbox
            class="mt-auto h-fit"
            [formControlName]="product.productType"
          >
          </mat-checkbox>
        </td>
      </tr>
    </table>

    <br />
    <div class="mb-1.5">
      <!-- <h4 class="text-primary-default space-y-2">
        Are you sure do you want to End Date?
      </h4> -->
      <div class="flex flex-grow">
        <b>End Date:</b>
      </div>
    </div>
    <div class="block items-start space-x-5">
      <mat-form-field
        appearance="outline"
        class="w-44 h-16 !ml-0 !mr-4 bg-white"
      >
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="picker1" formControlName="endDate" />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <mat-error
        *ngIf="form.hasError('enddate', 'endDate')"
      >
      Negative number not allowed
  </mat-error>
      </mat-form-field>
      <p *ngIf="error" class="text-red-600 error mt-0.5 !ml-0">
        * End date all of the payees under this principal Payee first, before
        the principal payee is end dated
      </p>
    </div>
    <div class="h-5"></div>

    <div class="space-x-2 flex justify-end">
      <button
        type="button"
        mat-raised-button
        [matDialogClose]="null"
        class="text-primary-default"
      >
        Cancel
      </button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="!bg-primary-500"
        (click)="save()"
        [disabled]="error || form.invalid"
      >
        Save
      </button>
    </div>
  </form>
</div>
