import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ColDef,
  ColGroupDef,
  IServerSideGetRowsParams,
  LoadSuccessParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';

import {
  exhaustMap,
  map,
  Observable,
  BehaviorSubject,
  switchMap,
  of,
  from,
  take,
} from 'rxjs';
import { ServerSideDatasource } from '@sg-overrides/ui/grid';
import {
  RouterInput,
  RouterOutput,
  trpcClient,
} from '@sg-overrides/shared/util-trpc';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { ExpireDialogComponent } from '../expire-dialog/expire-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BtnCellRendererComponent } from './button.component';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import {  ExpireButton } from './expirebutton.component';
import { GridProductColumnType, OverrideStore } from '../overrides.store';

export type programAgentCodesResponse = RouterOutput['dealers']['getProgramCode'];
export type programAgentDetails = programAgentCodesResponse[number];

export type dealerDetails = RouterOutput['dealers']['getDealers']['dealerList'][number];
export type dealerGroup=RouterOutput['dealers']['getDealerGroup'][number];
export type paymentMode=RouterOutput['dealers']['paymentMode'][number]

export type getOverridesResponse = RouterOutput['payee']['get'];
export type getOverridesTimeLine = getOverridesResponse['timeLine'][number];
export type getOverridesDatas = getOverridesResponse['overrideData'];
export type overrideData = getOverridesResponse['overrideData'][number];
export type overrideDataProducts = overrideData['products'];

export type updatePayeeRequest = RouterInput['payee']['update'];

export interface DetailViewOverridesData {
  payeeName: string;
  payeeCode: string;
  productType: string;
  amount: number;
  startDate: string;
  endDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class OverridesGridService {
  constructor(private dialog: MatDialog, private router: Router, private overrideStore: OverrideStore) {}
  // gridViewColumnDetails = [
  //   {
  //     columnDescription: 'PPM',
  //     columnToMap: 'PPM',
  //   },
  //   {
  //     columnDescription: 'Pre Paid Scheduled Maintenance',
  //     columnToMap: 'PPSM',
  //   },
  //   {
  //     columnDescription: 'GAP',
  //     columnToMap: 'GAP',
  //   },
  //   {
  //     columnDescription: 'Excess Wear & Use',
  //     columnToMap: 'WEAR',
  //   },
  //   {
  //     columnDescription: 'Term Protection Plus',
  //     columnToMap: 'TERM',
  //   },
  //   {
  //     columnDescription: 'Tier and Wheel Protection(FL only)',
  //     columnToMap: 'TW',
  //   },
  //   {
  //     columnDescription: 'Dent and Ding Protection(FL only)',
  //     columnToMap: 'DENT',
  //   },
  //   {
  //     columnDescription: 'Windshield Protection(FL only)',
  //     columnToMap: 'WS',
  //   },
  //   {
  //     columnDescription: 'Protection',
  //     columnToMap: 'PRO',
  //   },
  //   {
  //     columnDescription: 'Key Replacement',
  //     columnToMap: 'KEY',
  //   },
  //   {
  //     columnDescription: 'Appearance Protection',
  //     columnToMap: 'APP',
  //   },
  //   {
  //     columnDescription: 'Theft Protection',
  //     columnToMap: 'THEFT',
  //   },
  // ];
  // filteredOverrideList: any[] = [];
  // filteredOverridesGridList: any[] = [];

  // programAgentCodes$: BehaviorSubject<programAgentDetails[]> =
  //   new BehaviorSubject<programAgentDetails[]>([]);
  // dealerDetails$: BehaviorSubject<dealerDetails[]> = new BehaviorSubject<
  //   dealerDetails[]
  // >([]);

  // filteredOverridesByDate = new BehaviorSubject<getOverridesDatas>([]);
  // filteredOverridesByDateDetailView = new BehaviorSubject<
  //   DetailViewOverridesData[]
  // >([]);
  // overridesTimeLine = new BehaviorSubject<getOverridesTimeLine[]>([]);
  // overrideResponse$ = new BehaviorSubject<getOverridesResponse | null>(null);

  // selectedDealer$ = new BehaviorSubject<dealerDetails | null>(null);

  // public refetch = new BehaviorSubject<{ [key: string]: any }>({});

  // getProgramDetails() {
  //   trpcClient.dealers.getAgentCode
  //     .query()
  //     .then((response) => {
  //       this.programAgentCodes$.next(response);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // getDealerByProgram(programAgentCode: string) {
  //   trpcClient.dealers.getDealers
  //     .query(programAgentCode)
  //     .then((response) => {
  //       this.dealerDetails$.next(response);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // choosedDealer(dealer: dealerDetails | null) {
  //   this.selectedDealer$.next(dealer);
  //   if (dealer) {
  //     localStorage.setItem('dealerDetails', JSON.stringify(dealer));
  //   }
  // }

  // reset() {
  //   this.overrideResponse$.next(null);
  //   this.filteredOverridesByDate.next([]);
  //   this.overridesTimeLine.next([]);
  //   this.filteredOverridesByDateDetailView.next([]);
  // }

  // fetch(dealerCode: string) {
  //   this.filteredOverridesByDate.next([]);
  //   this.overridesTimeLine.next([]);

  //   trpcClient.payee.get.query(dealerCode).then((response) => {
  //     const currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

  //     this.overrideResponse$.next(response);

  //     const activeOverrideDate = response.timeLine.find(
  //       (timeLine) => timeLine.default === true
  //     );

  //     this.filteredOverrideList = this.getOverridesByDate(
  //       activeOverrideDate?.date ?? '',
  //       response.overrideData
  //     );

  //     this.filteredOverridesByDate?.next(this.filteredOverrideList);
  //     this.overridesTimeLine?.next(response.timeLine);
  //   });
  // }

  // getActiveOverrides(
  //   activeRecordDate: string,
  //   overridesData: getOverridesDatas
  // ) {
  //   const currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

  //   let filteredOverrides = overridesData.map((overrides) => {
  //     let overridesCopy = { ...overrides };

  //     overridesCopy.products = overrides.products.filter(
  //       (product) =>
  //         (new Date(currentDate) <= new Date(product.startDate) &&
  //           new Date(product.startDate) <= new Date(activeRecordDate)) ||
  //         (new Date(currentDate) <= new Date(product.endDate) &&
  //           new Date(product.endDate) <= new Date(activeRecordDate)) ||
  //         (product.endDate === '9999-12-31')
  //     );

  //     return overridesCopy;
  //   });

  //   filteredOverrides = filteredOverrides.filter(
  //     (overrides) => overrides.products.length > 0
  //   );
  //   this.formatOverridesDataForDetailView(filteredOverrides);
  //   return filteredOverrides;
  // }

  // getOverridesByDate(
  //   viewingRecordDate: string,
  //   overridesData: getOverridesDatas
  // ) {
  //   let filteredOverrides = overridesData.map((overrides) => {
  //     let overridesCopy = { ...overrides };

  //     overridesCopy.products = overrides.products.filter(
  //       (product: any) =>
  //         new Date(product.startDate) <= new Date(viewingRecordDate) &&
  //         new Date(product.endDate) >= new Date(viewingRecordDate)
  //     );

  //     return overridesCopy;
  //   });
  //   filteredOverrides = filteredOverrides.filter(
  //     (overrides) => overrides.products.length > 0
  //   );
  //   this.formatOverridesDataForDetailView(filteredOverrides);
  //   return filteredOverrides;
  // }

  // formatOverridesDataForDetailView(overrideDatas: getOverridesDatas) {
  //   let detailViewOverridesData: any[] = [
  //     ...overrideDatas.map((overrideData) => {
  //       let overrides: any[] = [];
  //       overrideData.products.forEach((product) => {  
             
  //         overrides.push({
  //           payeeName: overrideData.payeeName,
  //           payeeCode: overrideData.payeecode,
  //           productType: product.productType,
  //           amount: product.amount,
  //           startDate: product.startDate,
  //           endDate: product.endDate,
  //         });
  //       });
       
  //       return overrides;
  //     }),
  //   ];
  //   console.log(detailViewOverridesData)
  //   detailViewOverridesData = this.flatten(detailViewOverridesData, 2, []);
  //   this.filteredOverridesByDateDetailView.next(detailViewOverridesData);
  // }

  // flatten(overridesData: any[], depth: number, result?: any[]) {
  //   for (let i = 0; i < overridesData.length; i++) {
  //     if (Array.isArray(overridesData[i]) && depth !== 0) {
  //       this.flatten(overridesData[i], depth - 1, result);
  //     } else if (!Array.isArray(overridesData[i]) || depth === 0) {
  //       result?.push(overridesData[i]);
  //     }
  //   }
  //   return result ?? [];
  // }

  // changeOverrideDataByDate(selectedTimeLine: getOverridesTimeLine) {
  //   this.overrideResponse$
  //     .pipe(
  //       take(1),
  //       map((overridesResponse) => {
  //         let filteredOverrideList = [];
  //         if (selectedTimeLine.default) {
  //           filteredOverrideList = this.getOverridesByDate(
  //             selectedTimeLine.date,
  //             overridesResponse?.overrideData ?? []
  //           );
  //         } else {
  //           filteredOverrideList = this.getOverridesByDate(
  //             selectedTimeLine.date,
  //             overridesResponse?.overrideData ?? []
  //           );
  //         }
  //         this.filteredOverridesByDate?.next(filteredOverrideList);
  //         this.formatOverridesDataForDetailView(filteredOverrideList);
  //       })
  //     )
  //     .subscribe();
  // }

  generateColumnsForGridView(
    addExpireButton: boolean,
    gridProductColumns: GridProductColumnType[]
  ): (ColDef | ColGroupDef)[] {
    return [
      {
        headerName: 'Payee Name and Code',
        children: [
          {
            headerName: 'Principal Payee',
            field: 'isPrincipal',
            cellStyle: {  textTransform: 'capitalize' },
            width: 150,
            headerTooltip: 'Principal Payee',
            cellRenderer: 'agGroupCellRenderer',
          },
          {
            headerName: 'Payee Code',
            field: 'payeecode',
            width: 120,
            headerTooltip: 'Payee Code',
          },
          {
            headerName: 'Payee Name',
            // headerName: 'Dealer(s) Representative(Payee Name) ',
            field: 'payeeName',
            width: 138,
            headerTooltip: 'Payee Name',
          },
        ],
      },
      {
        headerName: 'Override Amount for Products',
        children: this.generateColumnForProducts(addExpireButton, gridProductColumns),
      },
    ];
  }

  generateColumnForProducts(addExpireButton: boolean, gridProductColumns: GridProductColumnType[]) {
    let minWidth = 0;
    let colDef: ColDef[] = gridProductColumns.map((col) => {
      if (['PPM', 'GAP'].includes(col.productTypeDescription)) {
        minWidth = 92
      }else{
        minWidth = 122;
      }
      return {
        headerName: col.productTypeDescription,
        minWidth,
        flex: 2,
        headerTooltip: col.productTypeDescription,
        cellClass: 'ag-right-aligned-cell',
        valueFormatter: (value: ValueFormatterParams) =>
          this.valueFormatter(value, col.productTypeCode),
      };
    });
    if (addExpireButton) {
      colDef.push({
        headerName: 'Actions',      
        cellRendererFramework: BtnCellRendererComponent,
        width: 150,
        headerTooltip: 'Actions',
        // flex: 1,
        cellRendererParams: {
          clicked: (data: {clickedExpireBtn: boolean, clickedEditBtn: boolean, data: overrideData}) => {
            if(data.clickedExpireBtn) {
              const dialogResult = this.dialog.open(ExpireDialogComponent, {
                data: {
                  overrideDetails: data.data,
                  gridDes: gridProductColumns
                },
                width: '61vw',
              });
              dialogResult
                .afterClosed()
                .pipe(
                  take(1),
                  map((dialogResult) => {
                    if (dialogResult) {
                      this.updateOverrides(
                        dialogResult.selectedProductDetails,
                        dialogResult.endDate,
                        dialogResult.overrideDetails.payeecode,
                        dialogResult.overrideDetails.payeeName,
                        dialogResult.overrideDetails.isPrincipal,
                        dialogResult.overrideDetails.payeeId
                      );
                    }
                  })
                )
                .subscribe();
            } else if(data.clickedEditBtn) {
              const overrideId = data.data.payeecode
              this.router.navigateByUrl(`overrides/edit/${overrideId}`);
            }
          },
        },
      });
    }
    return colDef;
  }

  generateColumnForMasterGridView(addExpireButton:boolean,gridProductColumns: GridProductColumnType[]):(ColDef | ColGroupDef)[] {
    return[
      {
        headerName: 'Payee Code',
        field: 'payeecode',
        width: 120,
        headerTooltip: 'Payee Code',
      },
      {
        headerName: 'Payee Name',
        // headerName: 'Dealer(s) Representative(Payee Name) ',
        field: 'payeeName',
        width: 138,
        headerTooltip: 'Payee Name',
      },
     ...this.MasterGridProducts(addExpireButton,gridProductColumns)

    ]

  }

  MasterGridProducts(addExpireButton: boolean, gridProductColumns: GridProductColumnType[]){
    let minWidth = 0;
    let colDef: ColDef[] = gridProductColumns.map((col) => {
      if (['PPM', 'GAP'].includes(col.productTypeDescription)) {
        minWidth = 92
      }else{
        minWidth = 122;
      }
      return {
        headerName: col.productTypeDescription,
        minWidth,
        flex: 2,
        headerTooltip: col.productTypeDescription,
        cellClass: 'ag-right-aligned-cell',
        valueFormatter: (value: ValueFormatterParams) =>
          this.valueFormatter(value,col.productTypeCode),
      };
    });
    if (addExpireButton) {
      colDef.push({
        headerName: 'Actions',
        headerTooltip: 'Actions',
        cellRendererFramework: ExpireButton,
        width: 150,
        // flex: 1,
        cellRendererParams: {
          clicked: (data: {clickedExpireBtn: boolean, clickedEditBtn: boolean, data: overrideData}) => {
            if(data.clickedExpireBtn) {
              const dialogResult = this.dialog.open(ExpireDialogComponent, {
                data: {
                  overrideDetails: data.data,
                  gridDes: gridProductColumns
                },
                width: '61vw',
              });
              dialogResult
                .afterClosed()
                .pipe(
                  take(1),
                  map((dialogResult) => {
                    if (dialogResult) {
                      this.updateOverrides(
                        dialogResult.selectedProductDetails,
                        dialogResult.endDate,
                        dialogResult.overrideDetails.payeecode,
                        dialogResult.overrideDetails.payeeName,
                        dialogResult.overrideDetails.isPrincipal,
                        dialogResult.overrideDetails.payeeId
                      );
                    }
                  })
                )
                .subscribe();
            }
          },
        },
      });
    }
    return colDef;
  }

 

 



  updateOverrides(
    updateProductList: overrideDataProducts,
    endDate: string,
    payeeCode: string,
    payeeName: string,
    isPrincipal: boolean,
    payeeId: number
  ) {
    this.overrideStore.updateProducts$({
      endDate: endDate,
      payeeCode: payeeCode,
      payeeName: payeeName,
      updateProductList: updateProductList,
      isPrincipal,
      payeeId
    })
    // this.overrideResponse$
    //   .pipe(
    //     take(1),
    //     map((overridesResponse) => {
    //       let userDetails = JSON.parse(
    //         localStorage.getItem('userDetails') || '{}'
    //       );
    //       let updateProductRequest: updatePayeeRequest = [];
    //       updateProductList.forEach((product) => {
    //         updateProductRequest.push({
    //           dealerOverridesID: product.overrideId,
    //           dealerCode: overridesResponse?.dealerCode ?? '',
    //           dealerGroupCode: '',
    //           chargeBackStatus: 'CB',
    //           productType: product.productType,
    //           payeeCode: payeeCode,
    //           payeeName: payeeName,
    //           paymentType:product.paymentType,
    //           overrideAmount: Number(product.amount),
    //           effectiveDate: product.startDate,
    //           expirationDate: endDate,
    //           createdBy: userDetails?.name ?? '',
    //           action: 'EDIT',
    //           isPrincipal:false,
    //           principalPayeeCode:product.principalPayeeCode,
    //           principalDealerOverridesId:'',
    //           principalPayeeAmount: product.principalPayeeAmount
    //         });
    //       });

    //       trpcClient.payee.update
    //         .mutate(updateProductRequest)
    //         .then((response) => {
    //           this.fetch(overridesResponse?.dealerCode ?? '');
    //         });
    //     })
    //   )
    //   .subscribe();
  }

  valueFormatter(param: ValueFormatterParams, columnName: string): string {
    const product = param.data.products.find((product: any) => {
      return product.productType === columnName;
    });
    return product?.amount ? '$ ' + Number(product.amount).toFixed(2) : '---';
  }
}
