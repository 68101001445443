<div mat-dialog-title class="!flex items-center justify-between !text-primary-500">
    <h2>{{ headerText }}</h2>
    <button
      mat-icon-button
      color="primary"
      class="!text-primary-500"
      [matDialogClose]="matDialogCloseValue"      
    >
      <mat-icon class="icon">clear</mat-icon>
    </button>
  </div>