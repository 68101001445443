import { Component,Input,ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'sg-overrides-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
})
export class DialogHeaderComponent {
  @Input() headerText: string | null = null;
  @Input() matDialogCloseValue: unknown | null = null;
}
