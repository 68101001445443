// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from './environment.interface';

export const environment: Environment = {
  clientId: 'f2333a14-00b1-4707-88f8-73b847141270',
  tenantId: 'b45b2e14-b22d-4196-b48c-b1b5506c874a',
  redirectUri: 'https://roadrunner-rate-lookup-uat.herokuapp.com',
  apiUrl: 'https://sg-override-sidecar-api-dev.herokuapp.com/trpc',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
