import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { getRouterSelectors } from '@ngrx/router-store';
import {
  RouterInput,
  RouterOutput,
  trpcClient,
} from '@sg-overrides/shared/util-trpc';
import {
  combineLatest,
  EMPTY,
  exhaustMap,
  from,
  map,
  Observable,
  switchMap,
  takeUntil,
} from 'rxjs';
// import {
//   DetailViewOverridesData,
//   getOverridesDatas,
//   getOverridesResponse,
// } from './overrides/overrides-grid.service';
import { concatLatestFrom } from '@ngrx/effects';

export interface OverridesState {
  dealerGroup: DealerGroup[];
  selectedDealerGroup: DealerGroup | null;
  programDetails: ProgramAgentCode[];
  selectedProgram: ProgramAgentCode | null;
  overrideResponse: OverridesResponse | null;
  dealerList: DealerDetails[];
  filteredOverridesByDate: OverridesDatas;
  overridesDetailView: OverridesDetailView[];
  overridesTimeLine: OverridesTimeLine[];
  selectedDealer: DealerDetails | null;
  paymentTypes: PaymentType[];
  viewingAsDate: string | null;
  gridProductColumns: GridProductColumnType[];
  selectedSearchBy: string | null;
}

export interface OverridesDetailView {
  payeeName: string;
  payeeCode: string;
  productType: string;
  amount: number;
  startDate: string;
  endDate: string;
  createdBy: string;
  createdDate: string;
  modifiedBy: string | null;
  modifiedDate: string | null;
  isPrincipal: boolean;
  detailViewRows: DetailViewRows[];
}

export interface DetailViewRows {
  payeeName: string;
  payeeCode: string;
  productType: string;
  amount: number;
  startDate: string;
  endDate: string;
  createdBy: string;
  createdDate: string;
  modifiedBy: string | null;
  modifiedDate: string | null;
}

export interface UpdateProducts {
  updateProductList: OverrideDataProducts;
  endDate: string;
  payeeCode: string;
  payeeName: string;
  isPrincipal: boolean;
  payeeId: number;
  // principalDealerOverridesId: string | null,
  // principalPayeeAmount: string | null,
  // principalPayeeCode: string | null,
}

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getRouterSelectors((s: any) => s.router);

export type ProgramAgentCode =
  RouterOutput['dealers']['getProgramCode'][number];

export type PaymentType = RouterOutput['dealers']['paymentMode'][number];

export type DealerGroup = RouterOutput['dealers']['getDealerGroup'][number];
export type DealerDetails =
  RouterOutput['dealers']['getDealers']['dealerList'][number];

export type GridProductColumnType =
  RouterOutput['dealers']['getproductType']['productTypes'][number];

export type OverridesResponse = RouterOutput['payee']['get'];
export type OverridesTimeLine = OverridesResponse['timeLine'][number];
export type OverridesDatas = OverridesResponse['overrideData'];
export type OverrideData = OverridesResponse['overrideData'][number];
export type OverrideDataProducts = OverrideData['products'];

export type UpdateProductRequest = RouterInput['payee']['update'];

@Injectable({ providedIn: 'root' })
export class OverrideStore extends ComponentStore<OverridesState> {
  constructor() {
    super({
      dealerGroup: [],
      selectedDealerGroup: null,
      programDetails: [],
      selectedProgram: null,
      dealerList: [],
      overrideResponse: null,
      selectedDealer: null,
      filteredOverridesByDate: [],
      overridesDetailView: [],
      overridesTimeLine: [],
      paymentTypes: [],
      viewingAsDate: null,
      gridProductColumns: [],
      selectedSearchBy: null,
    });

    this.selectSelectedProgram$
      .pipe(
        takeUntil(this.destroy$),
        map((program) => {
          if (program) {
            this.loadGridProductColumns(program.programAgentCode);
            this.loadDealerList(program.programAgentCode);
            this.loadDealeGroup(program.programId);
          } else {
            this.reset();
          }
        })
      )
      .subscribe();

    this.selectSelectedDealer$
      .pipe(
        takeUntil(this.destroy$),
        map((dealer) => {
          if (dealer) {
            this.loadOverrides(dealer.dealerId);
          }
        })
      )
      .subscribe();

    this.selectSelectedDealerGroup$
      .pipe(
        takeUntil(this.destroy$),
        map((dealer) => {
          if (dealer) {
            this.loadOverrides(dealer.dealerGroupId);
          }
        })
      )
      .subscribe();

    // combineLatest([this.viewingAsDate$, this.selectOverridesResponse$])
    //   .pipe(
    //     map(([date, overridesResponse]) => {
    //       console.log("kjsdksd", date)
    //       if (date && overridesResponse) {
    //         console.log(overridesResponse);

    //         console.log('trueeeeeeeee');
    //         let filteredOverrideList = this.getOverridesByDate(
    //           date,
    //           overridesResponse.overrideData
    //           );
    //           const childPayeeMapResponse = this.principalMastergrid(
    //             filteredOverrideList
    //           );
    //           console.log(childPayeeMapResponse);
    //         this.updateOverridesGridViewData(childPayeeMapResponse);
    //         //  }
    //         //  else{
    //         //   let filteredOverrideList = this.getOverridesByDate(
    //         //     date,
    //         //     overridesResponse.overrideData
    //         //   );
    //         //   this.updateOverridesGridViewData(filteredOverrideList);
    //         //  }
    //         //  let filteredOverrideList = this.getOverridesByDate(
    //         //   date,
    //         //   overridesResponse.overrideData
    //         // );

    //         let overridesDetailView =
    //           this.formatOverridesDataForDetailView(childPayeeMapResponse);
    //         this.updateOverridesDetailViewData(overridesDetailView);
    //       }
    //       return null;
    //     })
    //   ).subscribe()

    this.viewingAsDate$
      .pipe(
        // takeUntil(this.destroy$),
        concatLatestFrom((_: any) => this.selectOverridesResponse$),
        map(([date, overridesResponse]) => {
          if (date && overridesResponse) {
            let filteredOverrideList = this.getOverridesByDate(
              date,
              overridesResponse.overrideData
            );
            const childPayeeMapResponse =
              this.principalMastergrid(filteredOverrideList);
            this.updateOverridesGridViewData(childPayeeMapResponse);

            let overridesDetailView = this.formatOverridesDataForDetailView(
              childPayeeMapResponse
            );
            this.updateOverridesDetailViewData(overridesDetailView);
          }
          return null;
        })
      )
      .subscribe();
  }

  readonly selectState$ = this.select((state) => state);

  readonly selectProgramDetails$ = this.select((state) => state.programDetails);

  readonly selectSelectedProgram$ = this.select(
    (state) => state.selectedProgram
  );

  readonly selectSelectedSearchBy$ = this.select(
    (state) => state.selectedSearchBy
  );

  readonly selectedProgram$ = this.select((state) => state.selectedProgram);

  readonly selectDealerList$ = this.select((state) => state.dealerList);

  readonly selectSelectedDealer$ = this.select((state) => state.selectedDealer);

  readonly selectDealerGroup$ = this.select((state) => state.dealerGroup);

  readonly selectSelectedDealerGroup$ = this.select(
    (state) => state.selectedDealerGroup
  );

  readonly viewingAsDate$ = this.select((state) => state.viewingAsDate);

  readonly selectOverridesResponse$ = this.select(
    (state) => state.overrideResponse
  );

  readonly isPrincipal$ = this.select(
    (state) => state.overrideResponse?.overrideData ?? null
  );

  readonly selectFilteredOverrides$ = this.select(
    (state) => state.filteredOverridesByDate
  );

  readonly selectFilteredOverridesDetailView$ = this.select(
    (state) => state.overridesDetailView
  );

  readonly selectOverridesTimeLine$ = this.select(
    (state) => state.overridesTimeLine
  );

  readonly selectPaymentType$ = this.select((state) => state.paymentTypes);

  readonly selectGridProductColumns$ = this.select(
    (state) => state.gridProductColumns
  );

  //   readonly editPayeeDetails$ = this.select(this.selectedOverrideId$, (state) => {
  //     console.log(state.dealerList);
  //     return state;
  //   });

  readonly getPayee$ = this.select((state) => state.filteredOverridesByDate);

  //#region Effect

  readonly loadPrograms = this.effect(($) => {
    return $.pipe(
      switchMap(() => {
        return from(trpcClient.dealers.getProgramCode.query()).pipe(
          tapResponse((response) => {
            this.updateProgramList(response);
          }, console.log)
        );
      })
    );
  });

  readonly selectedProgram = this.effect(
    (program$: Observable<ProgramAgentCode>) => {
      return program$.pipe(
        map((program) => {
          localStorage.setItem('programDetails', JSON.stringify(program));
          this.updateSelectedProgram(program);
        })
      );
    }
  );

  readonly loadGridProductColumns = this.effect(
    (programAgentCode$: Observable<string>) => {
      return programAgentCode$.pipe(
        switchMap((programAgentCode) => {
          return from(
            trpcClient.dealers.getproductType.query(programAgentCode)
          ).pipe(
            tapResponse((response) => {
              this.updateGridProductColumns(response.productTypes);
            }, console.log)
          );
        })
      );
    }
  );

  readonly loadDealeGroup = this.effect(
    (programAgentId$: Observable<number>) => {
      return programAgentId$.pipe(
        switchMap((programAgentId) => {
          return from(
            trpcClient.dealers.getDealerGroup.query(programAgentId)
          ).pipe(
            tapResponse((response) => {
              this.updateDealerGroup(response);
            }, console.log)
          );
        })
      );
    }
  );

  readonly loadSelectedDealerGroup = this.effect(
    (dealer$: Observable<DealerGroup | null>) => {
      return dealer$.pipe(
        map((dealer) => {
          if (dealer) {
            localStorage.setItem('dealerGroup', JSON.stringify(dealer));
          } else {
            localStorage.removeItem('dealerGroup');
          }
          this.updateSelectedDealerGroup(dealer);
        })
      );
    }
  );

  readonly loadDealerList = this.effect(
    (programAgentCode$: Observable<string>) => {
      return programAgentCode$.pipe(
        switchMap((programAgentCode) => {
          return from(
            trpcClient.dealers.getDealers.query(programAgentCode)
          ).pipe(
            tapResponse((response) => {
              this.updateDealerList(response.dealerList);
            }, console.log)
          );
        })
      );
    }
  );

  readonly loadSelectedDealer = this.effect(
    (dealer$: Observable<DealerDetails | null>) => {
      return dealer$.pipe(
        map((dealer) => {
          if (dealer) {
            localStorage.setItem('dealerDetails', JSON.stringify(dealer));
          } else {
            localStorage.removeItem('dealerDetails');
          }
          this.updateSelectedDealer(dealer);
        })
      );
    }
  );

  readonly loadPaymentType = this.effect(($) => {
    return $.pipe(
      switchMap(() => {
        return from(trpcClient.dealers.paymentMode.query()).pipe(
          tapResponse((response) => {
            return this.updatePaymentType(response);
          }, console.log)
        );
      })
    );
  });

  // readonly loadOverrides = this.effect((dealerCode$: Observable<any>) => {
  //   return dealerCode$.pipe(
  //     switchMap((dealerCode) => {
  //       this.resetOverridesResponse();
  //       return from(trpcClient.payee.get.query({
  //         dealerGroupId:dealerCode??null,
  //         dealerId:dealerCode??null,
  //       })).pipe(
  //         tapResponse((response) => {
  //           const currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

  //           this.updateOverridesResponse(response);

  //           const activeOverrideDate = response.timeLine.find(
  //             (timeLine) => timeLine.default === true
  //           );

  //           this.updateOverridesTimeLine(response.timeLine);
  //           this.updateViewingAsDate(activeOverrideDate?.date ?? '');
  //           console.log('Load Overrides');
  //         }, console.log)
  //       );
  //     })
  //   );
  // });

  readonly loadOverrides = this.effect((dealer$: Observable<any>) => {
    return dealer$.pipe(
      concatLatestFrom((_) => [
        this.selectSelectedDealer$,
        this.selectSelectedDealerGroup$,
      ]),
      exhaustMap(([_, dealer, dealerGroup]) => {
        this.resetOverridesResponse();

        return from(
          trpcClient.payee.get.query({
            dealerGroupId: dealerGroup?.dealerGroupId ?? null,
            dealerId: dealer?.dealerId ?? null,
          })
        ).pipe(
          tapResponse(
            (response) => {
              const currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

              this.updateOverridesResponse(response);

              const activeOverrideDate = response.timeLine.find(
                (timeLine: { default: boolean }) => timeLine.default === true
              );

              this.updateOverridesTimeLine(response.timeLine);
              this.updateViewingAsDate(activeOverrideDate?.date ?? '');
            },
            (error) => {
              console.log(error);
            }
          )
        );
      })
    );
  });

  readonly loadDatasForEditPayee = this.effect(($) => {
    return $.pipe(
      map((_) => {
        const dealerDetails = localStorage.getItem('dealerDetails');
        const dealerGroup = localStorage.getItem('dealerGroup');
        if (dealerDetails) {
          this.updateSelectedDealer(JSON.parse(dealerDetails) as DealerDetails);
        } else {
          //Route to list page
        }

        if (dealerGroup) {
          this.updateSelectedDealerGroup(
            JSON.parse(dealerGroup) as DealerGroup
          );
        }
      })
    );
  });

  readonly updateProducts$ = this.effect(
    (updateProductList$: Observable<UpdateProducts>) =>
      updateProductList$.pipe(
        concatLatestFrom((_) => [
          this.selectSelectedDealer$,
          this.selectSelectedDealerGroup$,
          this.selectSelectedProgram$,
        ]),
        switchMap(([update, dealerDetails, dealerGroup, programDetails]) => {
          let dealer: DealerDetails | null = dealerDetails
            ? { ...dealerDetails }
            : null;
          let program: ProgramAgentCode | null = programDetails
            ? { ...programDetails }
            : null;

          let dealergroup: DealerGroup | null = dealerGroup
            ? { ...dealerGroup }
            : null;
          let userDetails = JSON.parse(
            localStorage.getItem('userDetails') || '{}'
          );
          let updateProducts: UpdateProductRequest = [];
          update.updateProductList.forEach((product) => {
            updateProducts.push({
              OverridesID: product.overrideId,
              // dealerCode: dealerDetails?.dealerCode ?? '',
              dealerId: dealerDetails?.dealerId ?? null,
              dealerGroupId: dealerGroup?.dealerGroupId ?? null,
              programId: program?.programId ?? null,
              // dealerGroupCode: '',
              // chargeBackStatus: 'CB',
              // productType: product.productType,
              productTypeId: product.productTypeId,
              payeeCode: update.payeeCode,
              payeeName: update.payeeName,
              payeeId: update.payeeId,
              paymentType: product.paymentType,
              overrideAmount: Number(product.amount),
              effectiveDate: product.startDate,
              expirationDate: update.endDate,
              createdBy: userDetails?.name ?? '',
              isPrincipal: update.isPrincipal,
              action: 'EDIT',
              principalOverridesID: product.principalOverridesId,
            });
          });

          return from(trpcClient.payee.update.mutate(updateProducts)).pipe(
            tapResponse((response) => {
              // this.updateSelectedDealer(null);
              // if(dealer && dealer.dealerCode) {
              //   this.loadOverrides(dealer.dealerCode);
              //   setTimeout(() => {
              this.updateSelectedDealer(dealer ?? null);
              this.updateSelectedDealerGroup(dealergroup ?? null);

              // }, 100);
              // }
            }, console.log)
          );
        })
      )
  );

  //#End Effect region

  //#Region Reducer
  readonly loadFilteredOverrides = this.updater(
    (state: OverridesState, filteredOverrides: OverrideData[]) => {
      return {
        ...state,
        filteredOverridesByDate: filteredOverrides,
      };
    }
  );

  readonly updateProgramList = this.updater(
    (state: OverridesState, programs: ProgramAgentCode[]) => {
      return {
        ...state,
        programDetails: programs,
      };
    }
  );

  readonly updateSelectedProgram = this.updater(
    (state: OverridesState, program: ProgramAgentCode | null) => {
      return {
        ...state,
        selectedProgram: program,
      };
    }
  );

  readonly updateDealerGroup = this.updater(
    (state: OverridesState, dealerGroup: DealerGroup[]) => {
      return {
        ...state,
        dealerGroup: dealerGroup,
      };
    }
  );

  readonly updateSelectedDealerGroup = this.updater(
    (state: OverridesState, dealergroup: DealerGroup | null) => {
      return {
        ...state,
        selectedDealerGroup: dealergroup,
      };
    }
  );

  readonly updateDealerList = this.updater(
    (state: OverridesState, dealerList: DealerDetails[]) => {
      return {
        ...state,
        dealerList: dealerList,
      };
    }
  );

  readonly updateSelectedDealer = this.updater(
    (state: OverridesState, dealer: DealerDetails | null) => {
      return {
        ...state,
        selectedDealer: dealer,
      };
    }
  );

  readonly updateViewingAsDate = this.updater(
    (state: OverridesState, date: string | null) => {
      return {
        ...state,
        viewingAsDate: date,
      };
    }
  );

  readonly updatePaymentType = this.updater(
    (state: OverridesState, paymentTypes: PaymentType[]) => {
      return {
        ...state,
        paymentTypes: paymentTypes,
      };
    }
  );

  readonly updateOverridesResponse = this.updater(
    (state: OverridesState, overridesResponse: OverridesResponse) => {
      return {
        ...state,
        overrideResponse: overridesResponse,
      };
    }
  );

  readonly updateOverridesGridViewData = this.updater(
    (state: OverridesState, overrides: OverridesDatas) => {
      return {
        ...state,
        filteredOverridesByDate: overrides,
      };
    }
  );

  readonly updateOverridesDetailViewData = this.updater(
    (state: OverridesState, overrides: OverridesDetailView[]) => {
      return {
        ...state,
        overridesDetailView: overrides,
      };
    }
  );

  readonly updateOverridesTimeLine = this.updater(
    (state: OverridesState, timeLines: OverridesTimeLine[]) => {
      return {
        ...state,
        overridesTimeLine: timeLines,
      };
    }
  );

  readonly updateGridProductColumns = this.updater(
    (state: OverridesState, columnNames: GridProductColumnType[]) => {
      return {
        ...state,
        gridProductColumns: columnNames,
      };
    }
  );

  readonly updateSelectedSearchBy = this.updater(
    (state: OverridesState, searchBy: string | null) => {
      return {
        ...state,
        selectedSearchBy: searchBy,
      };
    }
  );

  readonly resetOverridesResponse = this.updater((state: OverridesState) => {
    return {
      ...state,
      viewingAsDate: null,
      filteredOverridesByDate: [],
      overridesDetailView: [],
      overridesTimeLine: [],
      paymentTypes: [],
      // selectedDealerGroup:null,
      // selectedDealer: null,
    };
  });

  readonly reset = this.updater((state: OverridesState) => {
    return {
      programDetails: state.programDetails,
      selectedProgram: null,
      dealerGroup: [],
      selectedDealerGroup: null,
      overrideResponse: null,
      dealerList: [],
      filteredOverridesByDate: [],
      overridesDetailView: [],
      overridesTimeLine: [],
      selectedDealer: null,
      paymentTypes: [],
      viewingAsDate: null,
      gridProductColumns: [],
      selectedSearchBy: null,
    };
  });

  //#Region Reducer End

  //#Region Function
  getOverridesByDate(viewingRecordDate: string, overridesData: OverridesDatas) {
    let filteredOverrides = overridesData.map((overrides) => {
      let overridesCopy = { ...overrides };

      overridesCopy.products = overrides.products.filter(
        (product: any) =>
          new Date(product.startDate) <= new Date(viewingRecordDate) &&
          new Date(product.endDate) >= new Date(viewingRecordDate)
      );

      return overridesCopy;
    });
    filteredOverrides = filteredOverrides.filter(
      (overrides) => overrides.products.length > 0
    );

    return filteredOverrides;
  }

  formatOverridesDataForDetailView(
    overrideDatas: OverridesDatas
  ): OverridesDetailView[] {
    let detailViewOverridesData: OverridesDetailView[][] = [
      ...overrideDatas.map((overrideData) => {
        let overrides: OverridesDetailView[] = [];
        if (overrideData.isPrincipal) {
          overrideData.products.forEach((product) => {
            let childPayeeBasedonId = (overrideData as any)[
              'detailViewRow'
            ].map((data: OverrideData) => {
              let modifiedData = { ...data };
              modifiedData.products = data.products.filter(
                (childProduct) =>
                  Number(childProduct?.principalOverridesId) ===
                  product.overrideId
              );
              return modifiedData;
            });
            childPayeeBasedonId = this.flatten(childPayeeBasedonId, 2, []);
            let childDetailViewData: DetailViewRows[] = [];
            childPayeeBasedonId.forEach((data: OverrideData) => {
              data.products.forEach((childProduct) => {
                childDetailViewData.push({
                  payeeName: data.payeeName,
                  payeeCode: data.payeecode,
                  productType: childProduct.productType,
                  amount: childProduct.amount,
                  startDate: childProduct.startDate,
                  endDate: childProduct.endDate,
                  createdBy: childProduct.createdBy,
                  createdDate: childProduct.createdDate,
                  modifiedBy: childProduct.modifiedBy,
                  modifiedDate: childProduct.modifiedDate,
                });
              });
            });
            overrides.push({
              payeeName: overrideData.payeeName,
              payeeCode: overrideData.payeecode,
              productType: product.productType,
              amount: product.amount,
              startDate: product.startDate,
              endDate: product.endDate,
              createdBy: product.createdBy,
              createdDate: product.createdDate,
              modifiedBy: product.modifiedBy,
              modifiedDate: product.modifiedDate,
              isPrincipal: overrideData.isPrincipal,
              detailViewRows: [...childDetailViewData],
            });
          });
        } else {
          overrideData.products.forEach((product) => {
            overrides.push({
              payeeName: overrideData.payeeName,
              payeeCode: overrideData.payeecode,
              productType: product.productType,
              amount: product.amount,
              startDate: product.startDate,
              endDate: product.endDate,
              createdBy: product.createdBy,
              createdDate: product.createdDate,
              modifiedBy: product.modifiedBy,
              modifiedDate: product.modifiedDate,
              isPrincipal: overrideData.isPrincipal,
              detailViewRows: [],
            });
          });
        }
        return overrides;
      }),
    ];
    let overridesDetailView = this.flatten(detailViewOverridesData, 2, []);
    return overridesDetailView;
  }

  flatten(overridesData: any[], depth: number, result?: any[]) {
    for (let i = 0; i < overridesData.length; i++) {
      if (Array.isArray(overridesData[i]) && depth !== 0) {
        this.flatten(overridesData[i], depth - 1, result);
      } else if (!Array.isArray(overridesData[i]) || depth === 0) {
        result?.push(overridesData[i]);
      }
    }
    return result ?? [];
  }

  changeOverrideDataByDate(selectedTimeLine: OverridesTimeLine) {
    // this.overrideResponse$
    //   .pipe(
    //     take(1),
    //     map((overridesResponse) => {
    //       let filteredOverrideList = [];
    //       if (selectedTimeLine.default) {
    //         filteredOverrideList = this.getOverridesByDate(
    //           selectedTimeLine.date,
    //           overridesResponse?.overrideData ?? []
    //         );
    //       } else {
    //         filteredOverrideList = this.getOverridesByDate(
    //           selectedTimeLine.date,
    //           overridesResponse?.overrideData ?? []
    //         );
    //       }
    //       this.filteredOverridesByDate?.next(filteredOverrideList);
    //       this.formatOverridesDataForDetailView(filteredOverrideList);
    //     })
    //   )
    //   .subscribe();
  }

  principalMastergrid(overridesData: OverridesDatas) {
    var parPrin: OverridesDatas = [];
    parPrin = [
      ...overridesData.filter(
        (payee) =>
          payee.products.filter(
            (payee: any) => payee.principalOverridesId === null
          ).length > 0
      ),
    ];

    var chilPrin: OverridesDatas = [
      ...overridesData.filter(
        (payee) =>
          payee.products.filter(
            (payee: any) => payee.principalOverridesId !== null
          ).length > 0
      ),
    ];

    var formattedOverrides: any[] = [];

    parPrin.forEach((overrides, index) => {
      formattedOverrides.push(overrides);
      if (overrides.isPrincipal) {
        const productOverrideIds = overrides.products.map(
          (product) => product.overrideId
        );
        var abc = chilPrin.filter(
          (data) =>
            data.products.filter(
              (xyz) =>
                productOverrideIds.indexOf(Number(xyz.principalOverridesId)) >
                -1
            ).length > 0
        );
        formattedOverrides[index]['detailViewRow'] = [...abc];
      }
    });

    return formattedOverrides;
  }

  //#Region Function End
}
