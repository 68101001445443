import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'btn-cell-renderer',
  template: `
    <div class="flex justify-between w-24 mt-0.5 items-center">
      <mat-icon class="cursor-pointer !text-primary-500" (click)="clickedEditButton()">edit</mat-icon
      ><button
        color="primary"
        class="!bg-primary-500 w-16 h-8 leading-none  rounded text-white "
        (click)="clickedExpiredButton()"
      >
        Expire
      </button>
    </div>
  `,
})
export class BtnCellRendererComponent {
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  clickedExpiredButton() {
    this.params.clicked({ clickedExpireBtn: true, clickedEditBtn: false, data: this.params.data });
  }

  clickedEditButton() {
    this.params.clicked({ clickedExpireBtn: false, clickedEditBtn: true, data: this.params.data });
  }
}
