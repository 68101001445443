import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppRouter } from '@sg-overrides/api/trpc';
import { TRPCClientError } from '@trpc/client';

@Component({
  selector: 'sg-overrides-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public error: TRPCClientError<AppRouter>
  ) {
    console.log("Error", this.error.shape)
  }
}
