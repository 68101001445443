import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BreadcrumbDirective } from './breadcrumbs/breadcrumbs.directive';
import { JumpLinkComponent } from './jump-link/jump-link.component';
import { RouterModule } from '@angular/router';
import { CheckboxRenderer } from './grid/checkbox.renderer.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TimelineComponent } from './timeline/timeline.component';
import { SharedMaterialModule } from './material/shared.material.module';
import { ErrorDialogComponent } from './ui-errors/dialog/error-dialog/error-dialog.component';
import { ErrorService } from './ui-errors/error.service';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedUiDialogModule } from '@sg-overrides/ui/dialog';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    RouterModule,
    SharedMaterialModule,
    MatDialogModule,
    SharedUiDialogModule
  ],
  declarations: [
    BreadcrumbsComponent,
    BreadcrumbDirective,
    JumpLinkComponent,
    CheckboxRenderer,
    TimelineComponent,
    ErrorDialogComponent,
    ErrorDialogComponent
  ],
  exports: [JumpLinkComponent, TimelineComponent],
  providers: [ErrorService]
})
export class UiSharedModule {}
