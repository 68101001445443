<sg-overrides-dialog-header
  [headerText]="'API Error'"
></sg-overrides-dialog-header>
<mat-dialog-content>
  <div class="error">
    <p class="whitespace-pre mb-8 text-base" [innerHtml]="error.shape?.message"></p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [matDialogClose]="null" class="ml-auto" cdkFocusInitial>
    Close
  </button>
</mat-dialog-actions>
