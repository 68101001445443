import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserDetails } from './layout/layout.model';

@Component({
  selector: 'sg-overrides-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'frontend';
  isLogined =false;
  userDetails: UserDetails = {
    userName: '',
    name: '',
  };

  constructor(private msalService: MsalService) {
    this.checkUserLogin();
  }

  checkUserLogin() {
    this.msalService.instance.handleRedirectPromise().then((res) => {
      if (res != null && res.account != null) {
        this.msalService.instance.setActiveAccount(res.account);
        this.userDetails = {
          userName: res.account.username,
          name: res.account.name ? res.account.name : '',
        };
        localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
        this.isLogined = true;
      } else {
        this.login();
      }
    });
  }

  login() {
    this.msalService.loginRedirect();
  }
}

