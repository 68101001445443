import { environment as localEnv } from './environment';
import { environment as devEnv } from './environment.dev';
import { environment as qaEnv } from './environment.qa';
import { environment as uatEnv } from './environment.uat';
import { environment as prodEnv } from './environment.prod';
import type { Environment } from './environment.interface';

function getEnvironment(url: string) {
  if (url.includes('localhost')) return 'LOCAL';
  if (url.includes('dev')) return 'DEV';
  if (url.includes('qa')) return 'QA';
  if (url.includes('uat')) return 'UAT';
  if (url.includes('live')) return 'PROD';
  return 'LOCAL';
}

function getConfig(env: 'LOCAL' | 'DEV' | 'QA' | 'UAT' | 'PROD'): Environment {
  switch (env) {
    case 'LOCAL':
      return localEnv;
    case 'DEV':
      return devEnv;
    case 'QA':
      return qaEnv;
    case 'UAT':
      return uatEnv;
    case 'PROD':
      return prodEnv;
    default:
      return localEnv;
  }
}

export function getEnvironmentConfig(url: string) {
  const env = getEnvironment(url);
  const config = getConfig(env);
  console.log(url);
  console.log(env);
  console.log(config);
  return config;
}
