import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule],
  declarations: [DialogHeaderComponent],
  exports:[DialogHeaderComponent]
  
})
export class SharedUiDialogModule {}
