// import { userDetails } from './../../../../../libs/frontend/data-access-dealer-products/src/lib/models/dealr-products.model';

import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawerMode, MatSidenavContent } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ProgramAgentCode } from 'libs/ui/overrides/src/lib/overrides.store';
import { programService } from 'libs/ui/overrides/src/lib/overrides/program.service';
import { filter, map, Subject, take, takeUntil } from 'rxjs';
import { ChangeProgramModalComponent } from '../change-program-modal/change-program-modal.component';
import { UserDetails } from './layout.model';

const baseUrl = 'https://roadrunner-rating-dev.herokuapp.com';

@Component({
  selector: 'rate-lookup-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  host: { class: 'block' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [programService]
})
export class LayoutComponent implements OnInit {
  public version: string = '1.0.0';
  navItems = [
    {
      icon: 'home',
      label: 'Home',
      href: `${baseUrl}/home`,
    },
    {
      icon: 'gpp_good',
      label: 'Products',
      href: `${baseUrl}/rating/products`,
    },
    {
      icon: 'paid',
      label: 'Rates',
      href: `${baseUrl}/rating/rates`,
    },
    {
      icon: 'store',
      label: 'Dealers',
      href: `${baseUrl}/dealers`,
    },
    {
      icon: 'add_moderator',
      label: 'Auto-Enrollments',
      href: `${baseUrl}/enrollments`,
    },
    {
      icon: 'payments',
      label: 'Override Payees',
      href: `${baseUrl}/override-payees`,
    },
    {
      icon: 'percent',
      label: 'NCB Percentages',
      href: `${baseUrl}/ncb-percentages`,
    }
  ];

  shouldShowProgram$ = this.programService.selectedProgram$;
  activeRoutername$=this.programService.selectedRoute$;


  @ViewChild(MatSidenavContent) sidenavContent!: MatSidenavContent;
  @HostBinding('class.is-mobile') isMobile?: boolean;

  menuOpened = false;
  DealerCode: string[] = ['YM75075'];
  sidenavMode!: MatDrawerMode;
  private destroyed$ = new Subject<void>();
  isSpinnerLoading = false;
  userDetails: UserDetails = {
    userName: '',
    name: '',
  };

  constructor(
    private router: Router,
    private msalService: MsalService,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private programService: programService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
    this.setUpResponsiveness();
    this.setUpSidenavScrollReset();

    const programDetails = localStorage.getItem('programDetails');

    if (programDetails) {
      console.log("Inside Inside")
      this.programService.changeProgram(JSON.parse(programDetails) as ProgramAgentCode)
      // this.overrideStore.updateSelectedProgram(
      //   JSON.parse(programDetails) as ProgramAgentCode
      // );
    }

   

  }

  private setUpResponsiveness() {
    this.breakpointObserver
      .observe('(max-width: 959px)')
      .pipe(
        map((breakpointState) => breakpointState.matches),
        takeUntil(this.destroyed$)
      )
      .subscribe((isMobile) => {
        if (isMobile && this.menuOpened) {
          this.menuOpened = true;
        } else if (!isMobile && !this.menuOpened) {
          this.menuOpened = false;
        }
        this.isMobile = isMobile;
        this.sidenavMode = isMobile ? 'over' : 'side';
        this.cdr.markForCheck();
      });
  }

  private setUpSidenavScrollReset() {
    this.router.events
      .pipe(
        filter(
          (e) =>
            // When on a mobile device the document is the scrolling element, and scroll position is handled
            // by the router via its `scrollPositionRestoration` feature, so we only need to scroll to top on
            // larger devices where the scrolling element is the mat-sidenav-content.
            !this.isMobile && e instanceof NavigationEnd
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.scrollSidenavToTop();
      });
  }

  switchProgramClicked(): void {
    this.dialog
      .open(ChangeProgramModalComponent)
      .afterClosed()
      .pipe(
        take(1),
        map((b) => {
          if (b) {
            this.programService.clearProgram();
            // this.router.navigateByUrl('overrides');
          }
        })
      )
      .subscribe();
  }

  private scrollSidenavToTop() {
    this.sidenavContent.scrollTo({ top: 0, left: 0 });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  logout() {
    this.msalService.logout();
  }
}
