// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from './environment.interface';

export const environment: Environment = {
  clientId: 'e54c5755-cd9c-4981-9e4f-c0e653eceb9a',
  tenantId: 'b45b2e14-b22d-4196-b48c-b1b5506c874a',
  redirectUri: 'https://sg-override-sidecar-dev.herokuapp.com',
  apiUrl: 'https://sg-override-sidecar-api-dev.herokuapp.com/trpc',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
