// import { ComponentType } from "@ag-grid-community/core";
import { Injectable, NgZone } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { ErrorDialogComponent } from "./dialog/error-dialog/error-dialog.component";
import { ErrorMessage } from "./interfaces/error-message.interface";
import { ComponentType } from '@angular/cdk/portal';
import { AppRouter } from "@sg-overrides/api/trpc";
import { TRPCClientError } from "@trpc/client";


@Injectable()
export class ErrorService {
  private errorDialogId = 'error-dialog';

  constructor(private dialog: MatDialog, private ngZone: NgZone) {}

//   showTimeoutError(): Observable<void> {
//     return this.show(TimeoutErrorDialogComponent, {});
//   }

  showError(error: ErrorMessage): Observable<void> {
    return this.show(ErrorDialogComponent, { data: error });
  }

//   showGraphqlErrors(errors: GraphqlErrorMessage[]): Observable<void> {
//     return this.show(GraphqlErrorDialogComponent, { data: { errors } });
//   }

  showTrpcError(error: TRPCClientError<AppRouter>): Observable<void> {
    let result: Observable<void> | null = null;
    this.ngZone.run(() => {
      result = this.show(ErrorDialogComponent, { data: error });
    });
    return result as unknown as Observable<void>;
  }

  private show<T, D>(
    component: ComponentType<T>,
    config: MatDialogConfig<D>
  ): Observable<void> {
    let dialog = this.dialog.getDialogById(this.errorDialogId);
    if (!dialog) {
      dialog = this.dialog.open(component, {
        ...config,
        width: '600px',
      });
    }
    return dialog.afterClosed();
  }
}
