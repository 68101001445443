<mat-sidenav-container class="h-screen">
  <mat-sidenav [mode]="sidenavMode" [(opened)]="menuOpened" #menuSidenav>
    <div class="flex flex-col h-screen w-64 bg-primary-500">
      <div class="w-full">
        <div class="pt-7 px-7">
          <div class="w-full">
            <img src="assets/images/logo-light.svg" />
            <div class="w-full text-center text-accent-default text-2xl">
              <span>Roadrunner</span>
            </div>
          </div>
        </div>

        <div class="text-center text-sm text-accent-default">
          {{ version }}
        </div>

        <mat-nav-list class="ml-3 mt-2">
          <a
            *ngFor="let navItem of navItems"
            mat-list-item
            disableRipple
            matRipple
            [attr.href]="navItem.href"
            class="rounded-tl-full rounded-bl-full"
          >
            <mat-icon mat-list-icon class="text-accent-default">
              {{ navItem.icon }}
            </mat-icon>
            <div mat-line class="text-accent-default">
              {{ navItem.label }}
            </div>
          </a> 
         <a
            mat-list-item
            disableRipple
            matRipple
            href="https://roadrunner-rate-lookup-dev.herokuapp.com/rateLookup/dealerProductsp"
            routerLinkActive="!bg-primary-darker"
            class="rounded-tl-full rounded-bl-full"
          >
            <mat-icon mat-list-icon class="text-accent-default">
              search
            </mat-icon>
         <div mat-line class="text-accent-default">Rate Lookup</div>
          </a>

          <a
          mat-list-item
          disableRipple
          matRipple
          routerLink="/overrides"
          routerLinkActive="!bg-primary-darker"
          class="rounded-tl-full rounded-bl-full"
        >
          <mat-icon mat-list-icon class="text-accent-default">
            business
          </mat-icon>
       <div mat-line class="text-accent-default">Sidecar Override</div>
        </a>
        <a
        mat-list-item
        disableRipple
        matRipple
        routerLink="/chargeback"
        routerLinkActive="!bg-primary-darker"
        class="rounded-tl-full rounded-bl-full"
      >
        <mat-icon mat-list-icon class="text-accent-default">
          business
        </mat-icon>
     <div mat-line class="text-accent-default">Sidecar Chargeback</div>
      </a>
      <a
      mat-list-item
      disableRipple
      matRipple
      routerLink="/chargebackConfig"
      routerLinkActive="!bg-primary-darker"
      class="rounded-tl-full rounded-bl-full"
    >
      <mat-icon mat-list-icon class="text-accent-default">
        business
      </mat-icon>
   <div mat-line class="text-accent-default">Sidecar Config</div>
    </a>
      
        </mat-nav-list>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="!bg-white">
    <div class="h-full w-full overflow-y-auto">
      <mat-toolbar class="!bg-accent-500">
        <button type="button" mat-icon-button (click)="menuSidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <ng-container *ngIf="(shouldShowProgram$ | async)">
          <button
            mat-icon-button
            type="button"
            aria-label="switch programs"
            (click)="switchProgramClicked()"
            class="mr-2"
          >
            <mat-icon class="text-success"> swap_horizontal_circle </mat-icon>
          </button>
          <h1 style="font-family: Archivo;"><b>{{(activeRoutername$ | async)}}- </b>{{ (shouldShowProgram$ | async)?.programName }}</h1>
          <!-- <button
            mat-icon-button
            type="button"
            aria-label="edit-program"
            (click)="onEditProgramClick()"
            class="scale-90"
          >
            <mat-icon>edit</mat-icon>
          </button> -->
        </ng-container>
        <div class="ml-auto flex items-center">
          <div class="mr-2">{{ userDetails.name }}</div>
          <mat-menu #accountMenu="matMenu">
            <button mat-menu-item (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>SIGNOUT</span>
            </button>
          </mat-menu>
          <button
            type="button"
            mat-icon-button
            matTooltip="Account"
            [matMenuTriggerFor]="accountMenu"
            class="topbar-button-right img-button"
          >
            <img src="assets/images/avatars/001-man.svg" alt="" />
          </button>
        </div>
      </mat-toolbar>
      <!-- <div class="shadow-md w-full">
        <div class="px-20 py-4">               
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-end space-x-5 mb-4">
              <app-breadcrumbs>               
                <li
                  appBreadcrumb
                  *ngIf="
                  DealerCode as DealerCode
                  "
                >
                  <button mat-button [matMenuTriggerFor]="productTypeMenu">                                 
                    
                    <span>Dealer:{{DealerCode}}</span>
                    <mat-icon >arrow_drop_down</mat-icon>
                  </button>
                </li>
              </app-breadcrumbs>
              <mat-menu #productTypeMenu>
                <ng-template matMenuContent>
                  <ng-container
                    *ngFor="let productType of DealerCode"
                  >
                    <button
                      mat-menu-item                     
                     
                    >
                      {{DealerCode }}
                    </button>
                  </ng-container>
                </ng-template>
              </mat-menu>
            </div>
            <div class="flex items-center justify-start space-x-5 mb-4">
              <button
              type="button"
              mat-raised-button
              color="primary"  
              (click)="addPayee()"           
            >
              Add Payee
            </button>
            </div>
          </div>
          <div class="flex items-center">
            <p>Stepper</p>
          </div>

          <div
            *ngIf="isSpinnerLoading"
            class="flex flex-col items-center justify-center absolute z-10 top-[calc(50%-50px)] left-[calc(50%-50px)]"
          >
            <mat-spinner class="!stroke-success"></mat-spinner>
          </div>
        </div> -->
      <!-- </div> -->
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
