// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { observable } from '@trpc/server/observable';
import { createTRPCProxyClient, TRPCLink, TRPCClientError } from '@trpc/client';
import { httpLink } from '@trpc/client/links/httpLink';
import { Subject } from 'rxjs';
import { AppRouter } from '@sg-overrides/api/trpc';
import { Environment } from '@env/environment.interface';
import { getEnvironmentConfig } from '@env/environment.loader';

const envConfig: Environment = getEnvironmentConfig(window.origin);

export const trpcError$ = new Subject<TRPCClientError<AppRouter>>();

const errorLink: TRPCLink<AppRouter> = () => {
  return ({ next, op }) => {
    return observable((observer) => {
      return next(op).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          trpcError$.next(err);
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
    });
  };
};

export const trpcClient = createTRPCProxyClient<AppRouter>({
  links: [
    errorLink,
    httpLink({
      url: envConfig.apiUrl,
      // This is gross, but it allows tests to pass
      // TODO: can we solve this some other way that doesn't touch the runtime of the app like this,
      // that isn't also a giant pain?
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fetch: window.fetch != null ? undefined : ({} as any),
    }),
  ],
});
